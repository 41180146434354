import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Privacypolicybreadcreumb from "../components/privacypolicy/privacypolicy-breadcumbs";

import Privacycontent from "../components/privacypolicy/privacy-content";

// markup
const PrivacypolicyPage = () => {
  return (
    <div>
      <Seo title="Privacy &amp; Cookie Policy" />

      <Layout isSticky>
        <section>
          <Privacypolicybreadcreumb />
        </section>
        <section>
          <Privacycontent />
        </section>
      </Layout>
    </div>
  );
};

export default PrivacypolicyPage;
