import React from "react";

import Calender from "../../assets/images/learn/calender.png";


import { Link } from "gatsby";

export default function Privacycontent() {
  return (
    <section className="privacy-content-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 terms"> Introduction </h4>
            <p className="theme-p-tag">
              {" "}
              The Sahaj Marg Spirituality Foundation, a non-profit organisation
              registered in US, Austin TX and each of its affiliates, namely
              Shri Ram Chandra Mission NPC, incorporated in South Africa
              (hereinafter referred to as "Company," "We" or “Us”) respects your
              privacy and is committed to protecting it through our compliance
              with this policy.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              This policy describes the types of information we may collect from
              you or that you may provide when you visit the website located at
              www.heartfulness.org, any of our social media sites, mobile apps
              or any other online site hosted or managed by us (hereinafter
              referred to as our "Site") and our practices for collecting,
              using, maintaining, protecting, and disclosing that information.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              This policy applies to information we collect on this Site and in
              email, text, and other electronic messages between you and this
              Site.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              It does not apply to information collected by us offline or
              through any other means, including on any other website operated
              by us or any third party or by any third party that may link to or
              be accessible from or on the Site.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, do not use the
              Site. By accessing or using this Site, you agree to the terms of
              this privacy policy. This policy may change from time to time as
              described below. Your continued use of this Site after we make
              changes is deemed to be acceptance of those changes, so please
              check the policy periodically for updates.{" "}
            </p>

            <h4 className="theme-h4 terms"> 1.Information We Collect </h4>
            <p className="theme-p-tag">
              {" "}
              We collect several types of information from and about users of
              the Site, including:
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Information that is about you but individually does not identify
              you, such as information collected through our use of cookies
              which is described in more detail below:{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● If you engage in email, text, and other electronic messages with
              this Site, information by which you may be personally identified,
              such as name, e-mail address, telephone number, and any other
              information which you provide to us; and{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● When enrolling in workshops, classes or other Services, we may
              collect certain personal information from you, such as your name,
              address, email address and other information that you submit to us
              (the above, collectively,  "personal information"). 
              We collect this information:
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Automatically as you navigate through the Site, which
              information may include usage details, IP addresses, and other
              information collected through cookies, web beacons, and other
              tracking technologies described below.
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Directly from you when you provide it to us.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Information We Collect Through Automatic Data Collection
              Technologies{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              As you navigate through and interact with the Site, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              including:
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Details of your visits to the Site, such as traffic data,
              location data, logs, and other communication data and the
              resources that you access and use on the Site; and
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Information about your computer and internet connection,
              including your IP address, operating system, and browser type.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              We also may use these technologies to collect information about
              your online activities over time and across third-party websites
              or other online services (behavioral tracking).{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              The information we collect automatically is only statistical data.
              It helps us to improve the Site and to deliver a better and more
              personalized service, including by enabling us to:
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Estimate our audience size and usage patterns;
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Store information about your preferences;{" "}
            </p>
            <p className="theme-p-tag"> ● Speed up your searches; and</p>
            <p className="theme-p-tag">
              {" "}
              ● Recognize you when you return to the Site.{" "}
            </p>
            <p className="theme-p-tag"> Information You Provide to Us </p>
            <p className="theme-p-tag">
              {" "}
              If you contact us on or through the Site, the information we may
              collect may include personal information such as name, e-mail
              address, telephone number, and any other information you provide
              to us, including records and copies of your correspondence.
            </p>
            <h4 className="theme-h4 terms"> 2. How We Use Your Information </h4>
            <p className="theme-p-tag">
              {" "}
              We use information that we collect about you or that you provide
              to us, including any personal information:{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To assist trainers in conducting training in person or remotely;
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To present the Site and its contents to you;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To provide you with information that you request from us;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To fulfill any other purpose for which you provide it; and{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● For any other purpose with your consent.
            </p>
            <h4 className="theme-h4 terms"> 3. Disclosure of Your Information </h4>
            <p className="theme-p-tag">
              {" "}
              We may disclose aggregated information about our users, and
              information that does not identify any individual, without
              restriction.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              We may disclose personal information that we collect or you
              provide as described in this privacy policy:{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of our assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which personal information held by us about the
              Site users is among the assets transferred;
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To fulfill the purpose for which you provide it; and{" "}
            </p>
            <p className="theme-p-tag"> ● With your prior written consent.</p>
            <p className="theme-p-tag">
              {" "}
              We may also disclose your personal information:{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request;
            </p>
            <p className="theme-p-tag">
              {" "}
              ● To enforce or apply our terms of use
        
                terms of Use
           
              and
            </p>
            <p className="theme-p-tag">
              {" "}
              ● If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of us, our customers, or others.
            </p>
            <h4 className="theme-h4"> 4. Our Use of Tracking Technologies </h4>
            <p className="theme-p-tag">
              {" "}
              The technologies we use for this automatic data collection may
              include:
            </p>
            <p className="theme-p-tag">
              {" "}
              <b> ● Cookies (or browser cookies). </b> A cookie is a small file
              placed on the hard drive of your computer. When you visit the
              Site, we send one or more cookies to your computer or other
              device. We use cookies to improve the quality of our service,
              including for storing user preferences, improving search results
              and ad selection, and tracking user trends, such as how people
              search. We may also use cookies to help advertisers and publishers
              serve and manage advertisements across the web and on our
              services. You can opt out of interest-based advertising entirely
              by cookie settings or permanently using a browser plugin.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b> ● Flash Cookies. </b> Certain features of the Site may use
              local stored objects (or Flash cookies) to collect and store
              information about your preferences and navigation to, from, and on
              the Site. Flash cookies are not managed by the same browser
              settings as are used for browser cookies. For information about
              managing your privacy and security settings for Flash cookies, see
              “Your Privacy Rights” below.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b> ● Web Beacons. </b> Pages of the Site may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit us, for
              example, to count users who have visited those pages and for other
              related website statistics (for example, recording the popularity
              of certain website content and verifying system and server
              integrity). When you visit the Site, we may place one or more
              cookies in your browser. These are required to enable to hold
              session information as you navigate from page to page within the
              Site. In addition, we use cookies on the Site to understand
              visitor and user preferences, improve their experience, and track
              and analyze usage, navigational and other statistical information.
            </p>
            <h4 className="theme-h4 terms"> 5. Our Retention of Your Information </h4>
            <p className="theme-p-tag">
              {" "}
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). When we have no
              ongoing legitimate business need to process your personal
              information, we will either delete or anonymize it, or, if this is
              not possible (for example, because your personal information has
              been stored in backup archives), then we will securely store your
              personal information and isolate it from any further processing
              until deletion is possible.
            </p>
            <h4 className="theme-h4 terms">
              {" "}
              6. Our Protection of Your Information{" "}
            </h4>
            <p className="theme-p-tag">
              {" "}
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information,
              transmission of personal information to and from the Site is at
              your own risk. You should only access the Site within a secure
              environment.
            </p>
            <h4 className="theme-h4 terms" > 7. Your Privacy Rights </h4>
            <p className="theme-p-tag">
              {" "}
              If you are resident in the Republic of South Africa and you
              believe we are unlawfully processing your personal information,
              you also have the right to complain to the Information Regulator
              (South Africa) who is empowered to monitor and enforce compliance
              by public and private bodies with the provisions of the Protection
              of Personal information Act 4 of 2013. You can find their contact
              details here: Complaints email:
              
                complaints.IR@justice.gov.za /
            
              General Enquiries email:
             
                infoeg@justice.gov.za /
             
              Telephone: 010 023 5200
            </p>
            <p className="theme-p-tag">
              {" "}
              Based on the provisions of the Protection of Personal information
              Act, in South Africa, you have the right to request access to the
              personal information we collect from you, change that information,
              or to request that we delete it . To request to review, update, or
              delete your personal information, please send an email to the
              email address included below. We will respond to your request as
              soon as is reasonably practicable. We offer no assurance that your
              information will be totally removed from the applicable databases
              if such information is required to comply with applicable law and
              regulations.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Upon your request to delete information we have stored about you,
              we will delete your information. We will notify you of the action
              taken in response to your request.{" "}
            </p>
            <h4 className="theme-h4 terms">
              {" "}
              8. International Transfers of Your Information{" "}
            </h4>
            <p className="theme-p-tag">
              {" "}
              If you are located in South Africa, your personal information will
              be transferred to and processed by our teams located in the India
              and United States in accordance with Chapter 9 of the Protection
              of Personal Information Act 4 of 2013. Please contact us through
              the contact information provided below if you would like to see a
              copy of the model contracts.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              If you otherwise live outside the US and India, or wish to attend
              our classes, workshops or other services outside those countries,
              your information will be passed to our selected partner
              organizations authorized to provide those services and they may
              contact you in connection with the services, and hold your
              information. Our partner organizations are responsible for how
              they use your information and you should consult their own privacy
              policies in that regard. We and our partner organizations may host
              your information in, and transfer your information to, countries
              outside your country of residence, including to the US and India
              and you consent to that transfer.
            </p>
            <h4 className="theme-h4 terms"> 9. Updates to this Policy </h4>
            <p className="theme-p-tag">
              {" "}
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated “Last updated” date below
              and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy policy, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy policy frequently to be informed of how
              we are protecting your information.
            </p>
            <h4 className="theme-h4 terms"> 10. Contacting Us </h4>
            <p className="theme-p-tag">
              {" "}
              If you have questions regarding this privacy policy or wish to
              obtain additional information, please send an e-mail to our
              Protection Team at{" "}
             
                {" "}
                privacy@heartfulness.org{" "}
       {" "}
            </p>
            <div>
                  <img src={Calender} alt="Universal Prayer" style={{
                    position: "absolute", width: "calc(2.25rem + 24px)"
                    , transition: "color .2s"
                  }} />
                <p className="col-md-8 pt-4 px-3 mx-5 theme-p-tag">Last updated: April, 2021</p>
                  </div>
          </div>
        </div>
      </div>
    </section>
  );
}
