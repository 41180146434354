import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Privacypolicybreadcreumb() {
  return (
    <div className="breadcumb-sub-page about-banner subpage-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12 pad40">
          <h3 className="heading-wrapper about-breadcub-title text-center" >
            {" "}
            Privacy and Cookie Policy{" "}
          </h3>

          <div classname="cent-item">
            <Breadcrumb>
            <Breadcrumb.Item href="/"><span  className="home-color-offerings">Home</span></Breadcrumb.Item>
                  <Breadcrumb.Item active><span  className="home-color-offerings"> Privacy and Cookie Policy</span> </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
